export const SET_USERDATA = "SET_USERDATA";
export const SET_JSON = "SET_JSON";
export const MENU_KEY = "MENU_KEY";
export const SET_SHOPDETAILS = "SET_SHOPDETAILS";
export const SET_OUTLETDETAILS = "SET_OUTLETDETAILS";
export const SET_VARIABLE = "SET_VARIABLE";

export const ADD_ITEM = "ADD_ITEM";
export const SET_BILL_ITEMS = "SET_BILL_ITEMS";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const REMOVE_COMPONENT = "REMOVE_COMPONENT";
export const SET_EXTRA_CHARGES = "SET_EXTRA_CHARGES";
export const CHANGE_COM_COUNT = "CHANGE_COM_COUNT";
export const INCREMENT_ITEM = "INCREMENT_ITEM";
export const DECREMENT_ITEM = "DECREMENT_ITEM";
export const SET_AEAR_SQFT = "SET_AEAR_SQFT";

export const SET_QUOT = "SET_QUOT";
export const ADD_TO_QUOT = "ADD_TO_QUOT";
export const REMOVE_QUOT_ITEM = "REMOVE_QUOT_ITEM";
export const SET_QUOT_ITEMS = "SET_QUOT_ITEMS";
export const SET_QUOT_EXTRA_CHARGES = "SET_QUOT_EXTRA_CHARGES";

export const ADD_SQFT_ITEM = "ADD_SQFT_ITEM";
export const REMOVE_SQFT_ITEM = "REMOVE_SQFT_ITEM";
export const SET_SQFT_BILL_ITEMS = "SET_SQFT_BILL_ITEMS";
export const SET_SQFT_COMPONENTS = "SET_SQFT_COMPONENTS";
export const ADD_SQFT_COMPONENT = "ADD_SQFT_COMPONENT";
export const REMOVE_SQFT_COMPONENT = "REMOVE_SQFT_COMPONENT";
export const CHANGE_SQFT_COM_COUNT = "CHANGE_SQFT_COM_COUNT";
export const CHANGE_SQFT_COM_COLOR = "CHANGE_SQFT_COM_COLOR";
export const SET_SQFT_EXTRA_CHARGES = "SET_SQFT_EXTRA_CHARGES";

export const SET_PI_ITEMS = "SET_PI_ITEMS";
export const CHANGE_PI_COM = "CHANGE_PI_COM";
export const SET_PI_EXTRA_CRG = "SET_PI_EXTRA_CRG";
