import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  message,
  Card,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";
import { checkAccess } from "./AuthorizationService";
import { getAllItemsPagination1 } from "./functionCall";
const { Option } = Select;

function AddEmployee(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [DOB, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [DOJ, setDOJ] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState("");
  const [itemFile, setItemFile] = useState("");
  const [itemFileBase64, setItemFileBase64] = useState("");
  const [documents, setDocuments] = useState({ PAN: "", aadhar: "" });
  const [emgDetails, setEmgDetails] = useState({
    name: "",
    phoneNumber: "",
  });
  const [bankDetailsMap, setBankDetailsMap] = useState({
    name: "",
    IFSC: "",
    acNo: "",
    branch: "",
  });
  const [salaryDetails, setSalaryDetails] = useState({
    basicSalary: 0,
    HRA: 0,
    transport: 0,
    medical: 0,
    PF: 0,
    ESI: 0,
    empPF: 0,
    empESI: 0,
    loanAdvance: "",
  });

  const genderArray = ["Male", "Female"];
  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewEmployee") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("ManageEmployee")) navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setName(res.name);
        setDOB(dayjs(moment(res.DOB).toDate()));
        setAddress(res.address);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setDOJ(dayjs(moment(res.DOJ).toDate()));
        setGender(res.gender);
        setRole(res.role);
        setImages(res.itemImages);
        setImagesURI(res.itemImages);
        setItemFile(res.itemFile);
        setDocuments(JSON.parse(res.documents));
        setEmgDetails(JSON.parse(res.emgDetails));
        setBankDetailsMap(JSON.parse(res.bankDetailsMap));
        setSalaryDetails(JSON.parse(res.salaryDetails));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async (image, file) => {
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination1("Employee", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "RF-0";

    let tempCounter = parseInt(lastNumber.slice(3)) + 1;
    let tempId = "RF-" + tempCounter;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          name: name,
          DOB: DOB.format("YYYY-MM-DD"),
          address: address,
          phoneNumber: "+91" + phoneNumber,
          DOJ: DOJ.format("YYYY-MM-DD"),
          gender: gender,
          role: role,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: tempId,
          addedBy: addedBy,
          type: "Employee",
          itemImages: image ?? images,
          itemFile: file ?? itemFile,
          documents: JSON.stringify(documents),
          emgDetails: JSON.stringify(emgDetails),
          bankDetailsMap: JSON.stringify(bankDetailsMap),
          salaryDetails: JSON.stringify(salaryDetails),
        },
      });
      message.error("Employee Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async (image, file) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          name: name,
          DOB: DOB.format("YYYY-MM-DD"),
          address: address,
          phoneNumber: "+91" + phoneNumber,
          DOJ: DOJ.format("YYYY-MM-DD"),
          gender: gender,
          role: role,
          updatedBy: updatedBy,
          itemImages: image ?? images,
          itemFile: file ?? itemFile,
          documents: JSON.stringify(documents),
          emgDetails: JSON.stringify(emgDetails),
          bankDetailsMap: JSON.stringify(bankDetailsMap),
          salaryDetails: JSON.stringify(salaryDetails),
        },
      });
      message.error("Employee updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name === "") return message.error("Please enter name");
    if (DOB === "") return message.error("Please enter date of birth");
    if (address === "") return message.error("Please enter address");
    if (phoneNumber === "") return message.error("Please enter phone number");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    if (DOJ === "") return message.error("Please enter date of joining");
    if (gender === "") return message.error("Please select gender");
    if (role === "") return message.error("Please select role");
    if (
      emgDetails.phoneNumber != "" &&
      phoneReg.test(emgDetails.phoneNumber) === false
    )
      return message.error("Please enter valid phone emergency number");
    if (documents.PAN == "") return message.error("Please enter PAN Number");
    if (documents.aadhar == "")
      return message.error("Please enter aadhar card number");
    if (bankDetailsMap.IFSC == "")
      return message.error("Please enter bank IFSC code");
    if (bankDetailsMap.acNo == "")
      return message.error("Please enter bank account number");
    if (bankDetailsMap.branch == "")
      return message.error("Please enter bank branch");
    if (salaryDetails.basicSalary == 0)
      return message.error("Please enter basic pay");
    if (salaryDetails.HRA == 0) return message.error("Please enter HRA");
    if (salaryDetails.transport == 0)
      return message.error("Please enter transport allowance");
    if (salaryDetails.medical == 0)
      return message.error("Please enter medical allowance");
    // if (salaryDetails.loanAdvance == 0)
    //   return message.error("Please enter loan and advance");
    setSpinnerState("true");

    var tempImage = undefined;
    if (
      (test.test === "addNewComponent" && imagesURI.length > 0) ||
      (test.test != "addNewComponent" && imagesURI != images)
    )
      try {
        const response = await fetch(api + "uploadImage", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            base64: imagesBase64,
            fileName: uuidv4() + ".jpeg",
          }),
        });
        const responseJson = await response.json();
        tempImage = responseJson.Location;
      } catch (error) {
        console.error(error);
      }
    var tempFile = undefined;
    if (itemFileBase64 != "")
      try {
        const response = await fetch(api + "uploadPDF", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            base64: itemFileBase64,
            fileName: uuidv4() + ".pdf",
          }),
        });
        const responseJson = await response.json();
        tempFile = responseJson.Location;
      } catch (error) {
        console.error(error);
      }

    if (test.test === "addNewEmployee") addItem(tempImage, tempFile);
    else editItem(tempImage, tempFile);
  };

  const onImageChange = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setImagesURI(URL.createObjectURL(files[0]));
    reader.onload = (e) => {
      setImagesBase64(e.target.result.replace(/^data:image\/\w+;base64,/, ""));
    };
  };

  const onFileChange = async (event) => {
    let temp = undefined;
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      temp = e.target.result.substr(reader.result.indexOf(",") + 1);
      setItemFileBase64(temp);
    };
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Enter Employee Details</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            <PlusOutlined />
            {test.test === "addNewEmployee"
              ? "Add Employee"
              : "Update Employee"}
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employee Name:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Employee Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={100}
              />
            </div>
          </Col>

          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                DOB:
              </label>
              <DatePicker
                className="input-box"
                placeholder="Date of birth"
                onChange={(date) => setDOB(date)}
                value={DOB}
                style={{ width: "100%", height: 40 }}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Address:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                maxLength={500}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Phone Number:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={10}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                DOJ:
              </label>
              <DatePicker
                className="input-box"
                placeholder="Date of joining"
                onChange={(date) => setDOJ(date)}
                value={DOJ}
                style={{ width: "100%", height: 40 }}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Gender:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                value={gender}
                onChange={(value) => {
                  setGender(value);
                }}
              >
                <Option value="">Select Gender</Option>
                {genderArray.map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Role:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                value={role}
                onChange={(value) => {
                  setRole(value);
                }}
              >
                <Option value="">Select Role</Option>
                {props.common.roleArray.map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Emergency Contact Name
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Emergency Contact Name"
                value={emgDetails.name}
                onChange={(e) =>
                  setEmgDetails({ ...emgDetails, name: e.target.value })
                }
                maxLength={100}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Emergency Contact Number
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Emergency Contact Number"
                value={emgDetails.phoneNumber}
                onChange={(e) =>
                  setEmgDetails({ ...emgDetails, phoneNumber: e.target.value })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                PAN Card
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="PAN Card"
                value={documents.PAN}
                onChange={(e) =>
                  setDocuments({ ...documents, PAN: e.target.value })
                }
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Aadhar Number"
                value={documents.aadhar}
                onChange={(e) =>
                  setDocuments({ ...documents, aadhar: e.target.value })
                }
                maxLength={12}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Bank Name
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Bank Name"
                value={bankDetailsMap.name}
                onChange={(e) =>
                  setBankDetailsMap({ ...bankDetailsMap, name: e.target.value })
                }
                maxLength={100}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Bank Account Number
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Bank Account Number"
                value={bankDetailsMap.acNo}
                onChange={(e) =>
                  setBankDetailsMap({ ...bankDetailsMap, acNo: e.target.value })
                }
                maxLength={20}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                IFSC Code
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="IFSC Code"
                value={bankDetailsMap.IFSC}
                onChange={(e) =>
                  setBankDetailsMap({ ...bankDetailsMap, IFSC: e.target.value })
                }
                maxLength={11}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Bank Branch
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Bank Branch"
                value={bankDetailsMap.branch}
                onChange={(e) =>
                  setBankDetailsMap({
                    ...bankDetailsMap,
                    branch: e.target.value,
                  })
                }
                maxLength={100}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Basic Pay
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Basic Pay"
                value={salaryDetails.basicSalary}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    basicSalary: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                HRA
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="HRA"
                value={salaryDetails.HRA}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    HRA: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Transport Allowance
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Transport Allowance"
                value={salaryDetails.transport}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    transport: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Medical Allowance
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Medical Allowance"
                value={salaryDetails.medical}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    medical: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                PF (%)
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="PF (%)"
                value={salaryDetails.PF}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    PF: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                ESI (%)
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="ESI (%)"
                value={salaryDetails.ESI}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    ESI: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employer PF (%)
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Employer PF (%)"
                value={salaryDetails.empPF}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    empPF: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employer ESI (%)
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Employer ESI (%)"
                value={salaryDetails.empESI}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    empESI: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Loan and Advance Installment
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Loan and Advance"
                value={salaryDetails.loanAdvance}
                onChange={(e) =>
                  setSalaryDetails({
                    ...salaryDetails,
                    loanAdvance: e.target.value,
                  })
                }
                maxLength={10}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Employee Image:
              </label>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="myImage"
                onChange={onImageChange}
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
              />
              <div style={{ marginLeft: "20%" }}>
                <img src={imagesURI} width={80} height={80} />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Employee Document(PDF):
              </label>
              <input
                type="file"
                accept=".pdf"
                name="myImage"
                onChange={onFileChange}
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
              />
              {itemFile != "" && (
                <a
                  href={itemFile}
                  style={{
                    marginRight: "10px",
                    marginLeft: "40px",
                    color: "dodgerblue",
                    textDecoration: "none",
                  }}
                >
                  Document
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
