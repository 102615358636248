import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBill.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  message,
  Card,
  Select,
  Modal,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TextField } from "@mui/material";
import { checkAccess } from "./AuthorizationService";
import * as queries from "../graphql/queries";
import moment from "moment";
import dayjs from "dayjs";
import { getAllItemsPagination1 } from "./functionCall";

const { Option } = Select;

function AddComponents(props) {
  const navigate = useNavigate();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [expCatList, setExpCatList] = useState([]);
  const [modal, setModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [paymentMode, setPaymentMode] = useState("");
  const [bankName, setBankName] = useState("");

  let test = useParams();

  const paymentModeArray = [
    "Cash",
    "Net-Banking",
    "Debit/Credit Card",
    "G-pay/UPI",
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getOutletDetail(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewRecord") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Expense")) navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      setItemName(res.itemName);
      setItemDescription(res.itemDescription);
      setTotalAmount(res.totalAmount);
      setDate(dayjs(moment(res.date).toDate()));
      setPaymentMode(res.paymentMode);
      setBankName(res.bankName);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getOutletDetail = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setExpCatList(res.expCatList ?? []);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination1("Expense", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "EX-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "EX-" + year + "-" + tempCounter;

    let balance = 0;
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res = allTodos.data.getItemById;
      let tempBlnc = JSON.parse(res.bankBlnc);
      balance = tempBlnc[bankName] ? tempBlnc[bankName].currentBalance : 0;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          itemName: itemName,
          itemDescription: itemDescription,
          totalAmount: totalAmount,
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "Expense",
          time: moment(new Date()).format("HH:mm:ss"),
          date: date.format("YYYY-MM-DD"),
          paymentMode: paymentMode,
          bankName: bankName,
          balance: balance - totalAmount,
        },
      });
      message.error("Expense Added");
      navigate(-1);
      updateBalance();
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateBalance = async () => {
    try {
      await API.graphql({
        query: mutations.updateBankBlnc,
        variables: {
          shopId: shopId,
          bankName: bankName,
          installmentAmount: totalAmount,
          from: "Expense",
          updatedBy: updatedBy,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          itemName: itemName,
          itemDescription: itemDescription,
          updatedBy: updatedBy,
        },
      });
      message.error("Expense updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (itemDescription == "") return alert("Please select category");
    if (itemName === "") return alert("Please enter expense details");
    if (totalAmount == "") return alert("Please enter total amount");
    if (isNaN(totalAmount)) return alert("Please enter valid total amount");
    if (paymentMode == "") return alert("Please select payment mode");
    if (
      (paymentMode == "Cash" && bankName != "Cash") ||
      (paymentMode != "Cash" && bankName == "Cash")
    )
      return alert("Please select bank name and category as Cash");
    setSpinnerState("true");
    if (test.test == "addNewRecord") addItem();
    else editItem();
  };

  const addNewCategory = async () => {
    if (newCategory == "") return alert("Please enter new category");
    let temp = [...expCatList];
    temp.push(newCategory);
    console.log(temp);
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: outletShopId,
          expCatList: temp,
        },
      });
      await getOutletDetail(outletShopId);
      setItemDescription(newCategory);
      setModal(false);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 10,
              }}
            >
              <h3 className="bill-heading">Enter Expense Details</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 10,
            }}
          >
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              onClick={() => checkInput()}
              disabled={spinnerState}
            >
              <PlusOutlined />
              {test.test == "addNewRecord" ? "Add Expense" : "Update Expense"}
            </Button>
          </Col>
        </Row>
        <div className="divider"></div>
        <Card
          style={{
            backgroundColor: "white",
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Select
                showSearch
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  height: 40,
                }}
                value={itemDescription}
                onChange={(value) => {
                  if (value == "New") setModal(true);
                  else setItemDescription(value);
                }}
              >
                <Option value="">Select a category</Option>
                {expCatList.map((value) => {
                  return <Option value={value}>{value}</Option>;
                })}
                <Option value="New">Add New</Option>
              </Select>
            </Col>
            <Col span={12}>
              {(test.test == "addNewRecord" && (
                <DatePicker
                  placeholder="Date of expense"
                  onChange={(date) => setDate(date)}
                  value={date}
                  style={{ width: "100%", height: 40 }}
                />
              )) || <label>{date.format("DD-MM-YYYY")}</label>}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                label="Expense Details"
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                inputProps={{
                  maxLength: 300,
                }}
                autoComplete="off"
              />
            </Col>
            <Col span={12}>
              {(test.test == "addNewRecord" && (
                <TextField
                  label="Total Amount"
                  size="small"
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin="normal"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  inputProps={{
                    maxLength: 10,
                  }}
                  autoComplete="off"
                />
              )) || <label>{totalAmount}</label>}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {(test.test == "addNewRecord" && (
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={paymentMode}
                  onChange={(value) => {
                    setPaymentMode(value);
                  }}
                >
                  <Option value="">Select a method</Option>
                  {paymentModeArray.map((value) => {
                    return <Option value={value}>{value}</Option>;
                  })}
                </Select>
              )) || <label>{paymentMode}</label>}
            </Col>
            <Col span={12}>
              {(test.test == "addNewRecord" && (
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={bankName}
                  onChange={(value) => {
                    setBankName(value);
                  }}
                >
                  <Option value="">Select a bank</Option>
                  {props.common.bankList.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              )) || <label>{bankName}</label>}
            </Col>
          </Row>
        </Card>
      </div>
      <Modal
        visible={modal}
        style={{ width: "100%" }}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            <TextField
              label="Enter new category"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              size="small"
              style={{ width: "90%" }}
              autoComplete="off"
            />
          </div>
          <div className="fieldStyle">
            <Button
              className="button"
              type="primary"
              onClick={() => addNewCategory()}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddComponents);
