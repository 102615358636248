import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker, Button } from "antd";
import {
  getTypeAndDateShopIdPagination,
  getItemsByTypeFunc,
  getItemsByDatePagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import * as queries from "../graphql/queries";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";
import GenLeadReportPDF from "./genLeadReportPDF";
import GenLeadUserReportPDF from "./genLeadUserReportPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense(props) {
  const navigate = useNavigate();
  const [shopId, setShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [allOutlets, setAllOutlets] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(true);
  const { RangePicker } = DatePicker;

  const headersStatus = [
    { label: "Status", key: "status" },
    { label: "Count", key: "count" },
  ];

  const headersOutlet = [
    { label: "Location", key: "location" },
    { label: "Count", key: "count" },
  ];

  const headersUsers = [
    { label: "User", key: "email" },
    { label: "Count", key: "count" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getAllItemsByType(user.attributes["custom:shopId"]);
          await getLeadsByOutlet(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportLeads")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let allUser = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );

    let tempUsers = {};
    allUser.map((item) => {
      tempUsers[item.email] = {};
      tempUsers[item.email].email = item.email;
      tempUsers[item.email].count = 0;
    });

    let allItems = await getTypeAndDateShopIdPagination(
      "Lead",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );

    let leadsByStatus = [];

    leadsByStatus = [
      {
        status: "New",
        count: allItems.filter((item) => item.status == "live").length,
      },
      {
        status: "Follow Up",
        count: allItems.filter((item) => item.status == "Follow Up").length,
      },
      {
        status: "Convert to Quote",
        count: allItems.filter((item) => item.status == "Convert to Quote")
          .length,
      },
      {
        status: "Cancelled",
        count: allItems.filter((item) => item.status == "Cancelled").length,
      },
    ];

    setReportList(leadsByStatus);

    if (allItems.length > 0)
      allItems.map((item) => {
        if (tempUsers[item.addedBy]) {
          tempUsers[item.addedBy].count = tempUsers[item.addedBy].count + 1;
        }
      });
    setAllUsers(Object.values(tempUsers));
  };

  const getLeadsByOutlet = async (shopId) => {
    let res = [];
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      res = [allTodos.data.getItemById];
    } catch (error) {
      alert(JSON.stringify(error));
    }

    let res1 = await getItemsByTypeFunc("Outlet", shopId);
    let allOutlets = [...res, ...res1];

    for (const item of allOutlets) {
      let listLeads = await getItemsByDatePagination(
        "Lead",
        item.id,
        dateRange[0].format("YYYY-MM-DD"),
        dateRange[1].format("YYYY-MM-DD")
      );
      item.count = listLeads.length;
    }

    setAllOutlets(allOutlets);
    setSpinnerState(false);
  };

  const downloadPDF = async () => {
    await pdf(
      <GenLeadReportPDF allOutlets={allOutlets} dateRange={dateRange} />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Leads-Outlet-${dateRange[0].format(
            "DD MMM YY"
          )}-${dateRange[1].format("DD MMM YY")}.pdf`
        );
      });
  };

  const downloadPDFUser = async () => {
    await pdf(
      <GenLeadUserReportPDF reportList={reportList} dateRange={dateRange} />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Leads-User-${dateRange[0].format("MMM YY")}-${dateRange[1].format(
            "MMM YY"
          )}.pdf`
        );
      });
  };

  useEffect(() => {
    if (shopId != "") {
      setReportList([]);
      setAllOutlets([]);
      setSpinnerState(true);
      getAllItemsByType(shopId);
      getLeadsByOutlet(shopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>
            {dateRange[0].format("DD MMM YY")}-
            {dateRange[1].format("DD MMM YY")}
          </h1>
          <p style={{ marginRight: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Card style={{ width: "100%" }}>
              <h2>Leads count by status for all outlets</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 150 }}>
                  <p style={{ fontWeight: "bold" }}>Status</p>
                  <p style={{ fontWeight: "bold" }}>Count</p>
                </div>
                {reportList.length > 0 &&
                  reportList.map((item) => {
                    return (
                      <>
                        <div style={{ display: "flex" }}></div>
                        <div style={{ width: 150 }}>
                          <p style={{ fontWeight: "bold" }}>{item.status}</p>
                          <p>{item.count}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </Card>
          </Col>
          <Col span={6}>
            {reportList.length > 0 && (
              <Button type="primary">
                <CSVLink
                  data={reportList}
                  headers={headersStatus}
                  filename={
                    "Leads-" +
                    dateRange[0].format("DD MMM YY") +
                    "-" +
                    dateRange[1].format("DD MMM YY") +
                    ".csv"
                  }
                >
                  Export to CSV
                </CSVLink>
              </Button>
            )}
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}></div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Leads count by all outlets</h2>
                {allOutlets.length > 0 && (
                  <Button type="primary">
                    <CSVLink
                      data={allOutlets}
                      headers={headersOutlet}
                      filename={
                        "Leads-" +
                        dateRange[0].format("DD MMM YY") +
                        "-" +
                        dateRange[1].format("DD MMM YY") +
                        ".csv"
                      }
                    >
                      Export to CSV
                    </CSVLink>
                  </Button>
                )}
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <p style={{ width: 250 }}>Location</p>
                  <p style={{ width: 250 }}>Count</p>
                </div>
                {allOutlets.map((item) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <p style={{ width: 250 }}>{item.location}</p>
                      <p style={{ width: 250 }}>{item.count}</p>
                    </div>
                  );
                })}
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Leads count by all users</h2>
                {allUsers.length > 0 && (
                  <Button type="primary">
                    <CSVLink
                      data={allUsers}
                      headers={headersUsers}
                      filename={
                        "Leads-" +
                        dateRange[0].format("DD MMM YY") +
                        "-" +
                        dateRange[1].format("DD MMM YY") +
                        ".csv"
                      }
                    >
                      Export to CSV
                    </CSVLink>
                  </Button>
                )}
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <p style={{ width: 250 }}>User</p>
                  <p style={{ width: 250 }}>Count</p>
                </div>
                {allUsers.map((item) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <p style={{ width: 250 }}>{item.email}</p>
                      <p style={{ width: 250 }}>{item.count}</p>
                    </div>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
