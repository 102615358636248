import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Select, Input, Button, Row, Col, Card } from "antd";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import GenerateOrderDetailsPDF from "./generateOrderDetailsPDF";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
const { Option } = Select;

function ReadQuotation(props) {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [PDFState, setPDFState] = useState(false);
  const [calculated, setCalculated] = useState([]);
  let { test } = useParams();

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            await getItemById();
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (props.common.shopType === "root") navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setDetails(res);
        setOrderDetails(JSON.parse(res.orderDetails));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = (key) => {
    let temp = { ...orderDetails };
    if (temp[key].profileLength == "" || temp[key].profileLength == undefined)
      return alert("Please select profile length");
    if (temp[key].wastage === "" || temp[key].wastage == undefined)
      return alert("Please enter wastage");
    if (temp[key].wastage != "" && isNaN(temp[key].wastage))
      return alert("Please enter wastage in number");
    calculateProfile(key);
  };

  const calculateProfile = async (key) => {
    console.log(key);
    let tempOrder = { ...orderDetails };
    let tempMap = {};
    let targetSum = parseFloat(tempOrder[key].profileLength * 30.48);
    let wastage = tempOrder[key].wastage;
    // console.log("targetSum out of loop " + targetSum);

    // for (const [key1, value1] of Object.entries(tempOrder)) {
    let profileList = tempOrder[key].profileList
      ? tempOrder[key].profileList.length > 0
        ? tempOrder[key].profileList
        : []
      : [];
    // console.log("profileList " + profileList);

    if (profileList.length > 0) {
      profileList.map((item) => {
        let profile = item.profile;
        if (tempMap[profile] == undefined) {
          tempMap[profile] = {};
          tempMap[profile].profile = profile;
          tempMap[profile].sideArray = [];
          tempMap[profile].cuttingList = [];
          tempMap[profile].combination = [];
          tempMap[profile].count = 0;
          tempMap[profile].joint = false;
        }
        let formula = item.formula.split(",");

        // console.log("If");
        for (const [key1, value1] of Object.entries(
          tempOrder[key].dimensionMap
        )) {
          if (formula[0] != "NA") {
            let widthSide = calSide(
              formula[0],
              value1.width,
              value1.height,
              value1.panelCount,
              wastage,
              item.less,
              item.more
            );
            widthSide = widthSide.toFixed(1);
            let widthCount = calSide(
              formula[1],
              0,
              0,
              value1.panelCount,
              0,
              0,
              0
            );
            // console.log(widthSide);
            // console.log(widthCount);
            while (widthSide > targetSum) {
              tempMap[profile].joint = true;
              widthSide = widthSide - targetSum;
              widthSide = parseFloat(widthSide.toFixed(1));
              tempMap[profile].count =
                tempMap[profile].count +
                value1.panelCount * value1.quantity * 2;
              for (let i = 0; i < value1.panelCount * value1.quantity * 2; i++)
                tempMap[profile].combination.push([targetSum]);
            }
            tempMap[profile].sideArray = [
              ...tempMap[profile].sideArray,
              ...Array(widthCount * value1.quantity).fill(
                parseFloat(widthSide)
              ),
            ];
          }

          if (formula[2] != "NA") {
            let heightSide = calSide(
              formula[2],
              value1.width,
              value1.height,
              value1.panelCount,
              wastage,
              item.less,
              item.more
            );

            heightSide = heightSide.toFixed(1);
            let heightCount = calSide(
              formula[3],
              0,
              0,
              value1.panelCount,
              0,
              0,
              0
            );
            // console.log(heightSide);
            // console.log(heightCount);

            while (heightSide > targetSum) {
              tempMap[profile].joint = true;
              heightSide = heightSide - targetSum;
              heightSide = parseFloat(heightSide.toFixed(1));
              tempMap[profile].count =
                tempMap[profile].count +
                value1.panelCount * value1.quantity * 2;
              for (let i = 0; i < value1.panelCount * value1.quantity * 2; i++)
                tempMap[profile].combination.push([targetSum]);
            }

            tempMap[profile].sideArray = [
              ...tempMap[profile].sideArray,
              ...Array(heightCount * value1.quantity).fill(
                parseFloat(heightSide)
              ),
            ];
          }
          if (formula[0] != "NA" && formula[2] != "NA") {
            let widthSide = calSide(
              formula[0],
              value1.width,
              value1.height,
              value1.panelCount,
              0,
              item.less,
              item.more
            );
            widthSide = widthSide.toFixed(1);
            let heightSide = calSide(
              formula[2],
              value1.width,
              value1.height,
              value1.panelCount,
              0,
              item.less,
              item.more
            );
            heightSide = heightSide.toFixed(1);
            let count = calSide(formula[1], 0, 0, value1.panelCount, 0, 0, 0);
            tempMap[profile].cuttingList.push(
              "(" +
                widthSide +
                "cm * " +
                heightSide +
                "cm ) * " +
                count * value1.quantity
            );
          } else if (formula[0] != "NA" && formula[2] == "NA") {
            let widthSide = calSide(
              formula[0],
              value1.width,
              value1.height,
              value1.panelCount,
              0,
              item.less,
              item.more
            );
            widthSide = widthSide.toFixed(1);
            let count = calSide(formula[1], 0, 0, value1.panelCount, 0, 0, 0);
            tempMap[profile].cuttingList.push(
              "(" + widthSide + "cm ) * " + count * value1.quantity
            );
          } else if (formula[0] == "NA" && formula[2] != "NA") {
            let heightSide = calSide(
              formula[2],
              value1.width,
              value1.height,
              value1.panelCount,
              0,
              item.less,
              item.more
            );
            heightSide = heightSide.toFixed(1);
            let count = calSide(formula[3], 0, 0, value1.panelCount, 0, 0, 0);
            tempMap[profile].cuttingList.push(
              "(" + heightSide + "cm ) * " + count * value1.quantity
            );
          }
        }
      });
    } else {
      console.log("else");
      let profile = "OTHERS";
      if (tempMap[profile] == undefined) {
        tempMap[profile] = {};
        tempMap[profile].profile = profile;
        tempMap[profile].sideArray = [];
        tempMap[profile].cuttingList = [];
        tempMap[profile].combination = [];
        tempMap[profile].count = 0;
        tempMap[profile].joint = false;
      }
      for (const [key1, value1] of Object.entries(
        tempOrder[key].dimensionMap
      )) {
        let widthSide =
          parseFloat(value1.width / value1.panelCount) + parseFloat(wastage);
        let heightSide = parseFloat(value1.height) + parseFloat(wastage);

        widthSide = parseFloat(widthSide.toFixed(1));
        heightSide = parseFloat(heightSide.toFixed(1));
        tempMap[profile].cuttingList.push(
          "(" +
            parseFloat(value1.width / value1.panelCount).toFixed(1) +
            "cm * " +
            parseFloat(value1.height).toFixed(1) +
            "cm ) * " +
            value1.panelCount * 2 * value1.quantity
        );

        while (widthSide > targetSum) {
          tempMap[profile].joint = true;
          widthSide = widthSide - targetSum;
          widthSide = parseFloat(widthSide.toFixed(1));
          tempMap[profile].count =
            tempMap[profile].count + value1.panelCount * value1.quantity * 2;
          for (let i = 0; i < value1.panelCount * value1.quantity * 2; i++)
            tempMap[profile].combination.push([targetSum]);
        }
        while (heightSide > targetSum) {
          tempMap[profile].joint = true;
          heightSide = heightSide - targetSum;
          heightSide = parseFloat(heightSide.toFixed(1));
          tempMap[profile].count =
            tempMap[profile].count + value1.panelCount * value1.quantity * 2;
          for (let i = 0; i < value1.panelCount * value1.quantity * 2; i++)
            tempMap[profile].combination.push([targetSum]);
        }
        tempMap[profile].sideArray = [
          ...tempMap[profile].sideArray,
          ...Array(value1.panelCount * 2 * value1.quantity).fill(widthSide),
          ...Array(value1.panelCount * 2 * value1.quantity).fill(heightSide),
        ];
      }
    }
    // }
    // console.log("tempMap " + JSON.stringify(tempMap));
    for (const [key, value] of Object.entries(tempMap)) {
      let sideArray = value.sideArray;
      let tempSum = targetSum;
      sideArray = sideArray.sort((a, b) => b - a);
      do {
        let arrayTotal = sideArray.reduce((a, b) => a + b, 0);
        if (arrayTotal <= tempSum) {
          let subset = [];
          subset.push(...sideArray);
          subset.map((item) => {
            const index1 = sideArray.indexOf(item);
            if (index1 > -1) sideArray.splice(index1, 1);
          });
          value.combination.push(subset);
          value.count++;
        } else {
          let temp = subsetSum(sideArray, tempSum);
          if (temp.length > 0) {
            let subset = temp[0];
            subset.map((item) => {
              const index1 = sideArray.indexOf(item);
              if (index1 > -1) sideArray.splice(index1, 1);
            });
            value.combination.push(subset);
            value.count++;
          } else tempSum = (tempSum - 0.1).toFixed(1);
        }
      } while (sideArray.length > 0);
    }
    // console.log(JSON.stringify(tempMap));
    tempOrder[key].profileMap = tempMap;
    let tempCalculated = [...calculated];
    if (!tempCalculated.includes(key)) tempCalculated.push(key);
    if (tempCalculated.length == Object.keys(tempOrder).length)
      setPDFState(true);
    setCalculated(tempCalculated);
    setOrderDetails(tempOrder);
  };

  const subsetSum = (n, t, p = [], s = 0, r = []) => {
    if (r.length > 0) return r;
    if (s < t) {
      for (let i = 0; i < n.length; i++) {
        subsetSum(n.slice(i + 1), t, [...p, n[i]], s + n[i], r); // Recursively search for subsets
      }
    } else if (s == t) r.push(p);

    return r;
  };

  const calSide = (formula, width, height, panelCount, wastage, less, more) => {
    let count = 0;
    count = eval(
      formula
        .replace(/width/g, width)
        .replace(/height/g, height)
        .replace(/panelCount/g, panelCount)
        .replace(/wastage/g, wastage)
        .replace(/less/g, less)
        .replace(/more/g, more)
    );
    return parseFloat(count);
  };

  const downloadPDF = async () => {
    await pdf(
      <GenerateOrderDetailsPDF
        item={details}
        orderDetails={JSON.stringify(orderDetails)}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${details.randomId}_ProfileDetails.pdf`);
      });
  };

  const setValue = (key, field, value) => {
    let temp = { ...orderDetails };
    temp[key][field] = value;
    temp[key].profileMap = {};
    setOrderDetails(temp);
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <p className="bill-heading">Order Details: {details.randomId}</p>
        <Card style={{ marginTop: 20 }}>
          <div style={{ backgroundColor: "white" }}>
            {Object.entries(orderDetails).length > 0 &&
              Object.entries(orderDetails).map(([key, value]) => {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        backgroundColor: "#d1d6dc",
                        fontWeight: "bold",
                      }}
                    >
                      <p style={{ width: "20%" }}>Product Name</p>
                      <p style={{ width: "20%" }}>Instructions</p>
                      <p style={{ width: "20%" }}>Profile Color</p>
                      <p style={{ width: "20%" }}>Mesh Color</p>
                      <p style={{ width: "20%" }}>Width (cm)</p>
                      <p style={{ width: "20%" }}>Height (cm)</p>
                      <p style={{ width: "20%" }}>Panel Count</p>
                      <p style={{ width: "20%" }}>Quantity</p>
                      <p style={{ width: "20%" }}>Profile</p>
                      <p style={{ width: "20%" }}>Wastage (Per cut)</p>
                      <p style={{ width: "20%" }}>Action</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ width: "20%" }}>{value.itemName}</p>
                      <p style={{ width: "20%" }}>
                        {value.instructions != "" ? value.instructions : "NA"}
                      </p>
                      <p style={{ width: "20%" }}>{value.profileColor}</p>
                      <p style={{ width: "20%" }}>{value.meshColor}</p>
                      <div
                        style={{
                          flexDirection: "row",
                          width: "80%",
                        }}
                      >
                        {Object.values(value.dimensionMap)
                          .sort((a, b) => a.timestamp - b.timestamp)
                          .map((value1) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ width: "20%" }}>{value1.width}</p>
                                  <p style={{ width: "20%" }}>
                                    {value1.height}
                                  </p>
                                  <p style={{ width: "20%" }}>
                                    {value1.panelCount}
                                  </p>
                                  <p style={{ width: "20%" }}>
                                    {value1.quantity}
                                  </p>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <p style={{ width: "20%" }}>
                        <Select
                          className="addProdSelectStyle"
                          value={value.profileLength}
                          onChange={(value) => {
                            setValue(key, "profileLength", value);
                          }}
                          style={{ width: "70%" }}
                        >
                          <Option value="">Select</Option>
                          <Option value="7">7 Feet</Option>
                          <Option value="12">12 Feet</Option>
                          <Option value="16">16 Feet</Option>
                        </Select>
                      </p>
                      <p style={{ width: "20%" }}>
                        <Input
                          className="addProdInputStyle"
                          value={value.wastage}
                          onChange={(e) => {
                            setValue(key, "wastage", e.target.value);
                          }}
                          maxLength={6}
                          style={{ width: "50%" }}
                        />
                      </p>
                      <p style={{ width: "20%" }}>
                        <Button
                          type="primary"
                          onClick={() => checkInput(key)}
                          style={{ marginTop: 10, width: "70%" }}
                        >
                          Calculate Profile
                        </Button>
                      </p>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <p style={{ width: "20%" }}>Profile</p>
                        <p style={{ width: "10%" }}>Count</p>
                        <p style={{ width: "40%" }}>Combination</p>
                        <p style={{ width: "30%" }}>Cutting List</p>
                      </div>
                      <div>
                        {value.profileMap &&
                          Object.entries(value.profileMap).map(
                            ([key1, value1]) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ width: "20%" }}>{key1}</p>
                                  <p style={{ width: "10%" }}>
                                    {value1.count}
                                    {value1.joint && (
                                      <span style={{ color: "red" }}>
                                        Joint required
                                      </span>
                                    )}
                                  </p>
                                  <p style={{ width: "40%" }}>
                                    {value1.combination.map((item, index) => (
                                      <p>
                                        {index + 1} -{" "}
                                        {item.map(
                                          (value2) =>
                                            parseFloat(
                                              value2 - value.wastage
                                            ).toFixed(1) + "cm "
                                        )}{" "}
                                        -{" "}
                                        {(
                                          parseFloat(
                                            value.profileLength * 30.48
                                          ) - item.reduce((a, b) => a + b, 0)
                                        ).toFixed(1) + "cm wastage"}
                                      </p>
                                    ))}
                                  </p>
                                  <p style={{ width: "30%" }}>
                                    {value1.cuttingList.map((item, index) => (
                                      <p>
                                        {index + 1} - {item}
                                      </p>
                                    ))}
                                  </p>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Card>
      </div>
      {PDFState && (
        <Button style={{ margin: 10 }} onClick={downloadPDF} type="button">
          PDF
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReadQuotation);
