import { useNavigate, Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Switch, Modal, Button, DatePicker } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { getItemsByOutletPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import * as mutations from "../graphql/mutations";
import {
  getItemsByTypeFunc,
  getItemsByDatePagination,
  getItemIdByTypeFunc,
} from "./functionCall";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;

function ListComponents(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [onlyLowStock, setOnlyLowStock] = useState(false);
  const [itemTotal, setItemTotal] = useState("");
  const [itemCount, setItemCount] = useState("");
  const [showCal, setShowCal] = useState(false);
  const [inventoryDate, setInventoryDate] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [inventoryMap, setInventoryMap] = useState({});
  const [outletShopId, setOutletShopId] = useState("");
  const [allComponents, setAllComponents] = useState({});
  const test = useParams();

  const Columns = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      width: "10%",
      ellipsis: true,
      render: (itemName, record) => (
        <a
          style={{
            color: !isNaN(record.remainingQuantity)
              ? record.remainingQuantity < record.lowStockValue
                ? "red"
                : "black"
              : "black",
          }}
        >
          {itemName}
        </a>
      ),
    },
    {
      title: "Tags",
      dataIndex: "itemTags",
      key: "itemTags",
      width: "10%",
      ellipsis: true,
      render: (itemTags) =>
        itemTags && itemTags != "" ? (
          <a>
            {itemTags.map((item) => {
              return <a>{item}</a>;
            })}
          </a>
        ) : (
          "NA"
        ),
    },
    {
      title: "Raw",
      dataIndex: "rawMaterial",
      key: "rawMaterial",
      width: "10%",
      ellipsis: true,
      render: (rawMaterial) => rawMaterial ?? "NA",
    },
    {
      title: "In",
      dataIndex: "incomingQuantity",
      key: "incomingQuantity",
      width: "10%",
      ellipsis: true,
      render: (incomingQuantity) => incomingQuantity ?? "NA",
    },
    {
      title: "Out",
      dataIndex: "soldQuantity",
      key: "soldQuantity",
      width: "10%",
      ellipsis: true,
      render: (soldQuantity) => soldQuantity ?? "NA",
    },
    {
      title: "Stock",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      width: "10%",
      ellipsis: true,
      render: (remainingQuantity, record) => (
        <a
          style={{
            color: !isNaN(remainingQuantity)
              ? parseFloat(record.remainingQuantity) <
                parseFloat(record.lowStockValue)
                ? "red"
                : "black"
              : "black",
          }}
        >
          {remainingQuantity ?? "NA"}
        </a>
      ),
    },
    {
      title: "Color",
      dataIndex: "quantityLog",
      key: "quantityLog",
      ellipsis: true,
      width: "10%",
      render: (quantityLog) => (
        <a
          onClick={() => {
            setModal(true);
            setSelectedItem(quantityLog);
          }}
        >
          Colors
        </a>
      ),
    },
    ...(props.common.role == "Owner" || props.common.role == "Accountant"
      ? [
          {
            title: "PP",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    ...(props.common.role == "Owner" || props.common.role == "Accountant"
      ? [
          {
            title: "Amount",
            dataIndex: "remainingQuantity",
            key: "remainingQuantity",
            width: "10%",
            ellipsis: true,
            render: (remainingQuantity, record) =>
              (remainingQuantity ?? 0) * record.masterPP,
          },
        ]
      : []),
    {
      title: "History",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "10%",
      render: (id, record) => (
        <a
          onClick={() =>
            navigate(`../InventoryHistory/${id}/${record.itemName}`)
          }
        >
          History
        </a>
      ),
    },
    ...(test.test == "homepage"
      ? [
          {
            title: "Inventory",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "10%",
            render: (id) => (
              <a onClick={() => navigate(`../UpdateInventory/${id}`)}>Update</a>
            ),
          },
        ]
      : []),
  ];

  const ColumnsInventory = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "PP",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "In",
      dataIndex: "in",
      key: "in",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.in - b.in,
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "In Value",
            dataIndex: "inValue",
            key: "inValue",
            width: "10%",
            ellipsis: true,
            sorter: (a, b) => a.in - b.in,
          },
        ]
      : []),
    {
      title: "Out",
      dataIndex: "out",
      key: "out",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.out - b.out,
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Out Value",
            dataIndex: "outValue",
            key: "outValue",
            width: "10%",
            ellipsis: true,
            sorter: (a, b) => a.in - b.in,
          },
        ]
      : []),
  ];

  const headers = [
    { label: "Name", key: "itemName" },
    props.common.shopType == "master"
      ? { label: "Purchase Price", key: "masterPP" }
      : null,
    { label: "In", key: "in" },
    props.common.shopType == "master"
      ? { label: "In Value", key: "inValue" }
      : null,
    { label: "Out", key: "out" },
    props.common.shopType == "master"
      ? { label: "Out Value", key: "outValue" }
      : null,
  ];

  const headersInventory = [
    { label: "Name", key: "itemName" },
    { label: "Item Tags", key: "itemTagsString" },
    { label: "Raw Material", key: "rawMaterial" },
    { label: "Incoming Quantity", key: "incomingQuantity" },
    { label: "Sold Quantity", key: "soldQuantity" },
    { label: "Remaining Quantity", key: "remainingQuantity" },
    props.common.role == "Owner" || props.common.role == "Accountant"
      ? { label: "Purchase Price", key: "masterPP" }
      : null,
    props.common.role == "Owner" || props.common.role == "Accountant"
      ? { label: "Amount", key: "Amount" }
      : null,
    { label: "Color", key: "colorString" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(
            test.test == "homepage"
              ? user.attributes["custom:outletShopId"]
              : test.test
          );
          await getItemsByType(
            user.attributes["custom:shopId"],
            test.test == "homepage"
              ? user.attributes["custom:outletShopId"]
              : test.test
          );
          // await getIssueInInventory(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Inventory")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId, outletShopId) => {
    let temp = await getItemsByTypeFunc("Component", shopId);
    let itemCount = 0;
    let itemTotal = 0;

    let allItems = await getItemsByOutletPagination(
      "Inventory",
      "live",
      outletShopId
    );
    if (allItems.length > 0) {
      allItems.map((item) => {
        temp.map((value) => {
          if (value.id == item.itemId) {
            value.incomingQuantity = item.incomingQuantity;
            value.soldQuantity = item.soldQuantity;
            value.remainingQuantity = item.remainingQuantity;
            value.lowStockValue = item.lowStockValue;
            value.quantityLog = JSON.parse(item.quantityLog);
            value.itemTags = item.itemTags ?? [];
            // console.log(item.itemTags);
            value.itemTagsString = value.itemTags
              .map((value1) => `${value1}`)
              .join(", ");
            value.Amount = (item.remainingQuantity ?? 0) * value.masterPP;
            // console.log(value.itemTagsString);
            value.updatedDate = item.updatedDate;
            value.updatedTime = item.updatedTime;
            value.rawMaterial = item.rawMaterial;
            value.colorString = Object.entries(JSON.parse(item.quantityLog))
              .map(([key, value]) => `${key} ${value.remainingQuantity}`)
              .join(", ");
          }
        });
      });
    }

    temp.map((item) => {
      itemCount =
        parseFloat(itemCount) + parseFloat(item.remainingQuantity ?? 0);
      itemTotal =
        parseFloat(itemTotal) +
        parseFloat(item.remainingQuantity ?? 0) * parseFloat(item.masterPP);
    });

    temp = temp.sort((a, b) => {
      const dateTimeA = moment(
        (a.updatedDate || "") + " " + (a.updatedTime || ""),
        "YYYY-MM-DD HH:mm:ss"
      );
      const dateTimeB = moment(
        (b.updatedDate || "") + " " + (b.updatedTime || ""),
        "YYYY-MM-DD HH:mm:ss"
      );

      if (dateTimeA.isValid() && dateTimeB.isValid()) {
        return dateTimeB - dateTimeA;
      } else if (dateTimeA.isValid()) {
        return -1;
      } else if (dateTimeB.isValid()) {
        return 1;
      } else {
        return 0;
      }
    });

    setAllItems(temp);
    setItemCount(itemCount);
    setItemTotal(itemTotal);

    //converting component list to map

    let tempMap = {};
    temp.map((item) => {
      tempMap[item.id] = {};
      tempMap[item.id].id = item.id;
      tempMap[item.id].itemName = item.itemName;
      tempMap[item.id].masterPP = item.masterPP;
    });

    setAllComponents(tempMap);
    await getInventoryMap(outletShopId, tempMap);
  };

  const toggleButton = () => {
    setOnlyLowStock((prevState) => !prevState);
  };

  useEffect(() => {
    if (outletShopId != "") getInventoryMap(outletShopId, allComponents);
  }, [inventoryDate]);

  const getInventoryMap = async (outletShopId, allComponents) => {
    let allItems = await getItemsByDatePagination(
      "InventoryLog",
      outletShopId,
      inventoryDate[0].format("YYYY-MM-DD"),
      inventoryDate[1].format("YYYY-MM-DD")
    );
    // console.log(allItems.length);
    let temp = {};
    allItems.map((item) => {
      let id = item.itemId;
      if (temp[id] == undefined) {
        temp[id] = {};
        temp[id].id = id;
        temp[id].itemName = allComponents[id].itemName;
        temp[id].masterPP = allComponents[id].masterPP;
        temp[id].in = 0;
        temp[id].inValue = 0;
        temp[id].out = 0;
        temp[id].outValue = 0;
      }
      if (item.action == "Added") {
        temp[id].in = temp[id].in + parseFloat(item.quantity);
        temp[id].inValue =
          temp[id].inValue +
          parseFloat(item.quantity) * parseFloat(temp[id].masterPP);
      } else {
        temp[id].out = temp[id].out + parseFloat(item.quantity);
        temp[id].outValue =
          temp[id].outValue +
          parseFloat(item.quantity) * parseFloat(temp[id].masterPP);
      }
    });
    // console.log(JSON.stringify(temp));
    setInventoryMap(temp);
  };

  // const getIssueInInventory = async (outletShopId) => {
  //   let temp = await getItemsByTypeFunc("Component", outletShopId);
  //   temp.map(async (item) => {
  //     let allItems = await getItemIdByTypeFunc("Inventory", item.id);
  //     allItems = allItems.filter((item) => item.outletShopId == outletShopId);
  //     // console.log(allItems.length);
  //     if (allItems.length > 1) {
  //       console.log("issues-- " + item.id);
  //       console.log(allItems.length);
  //       // allItems.map((value) => {
  //       //   console.log(value.id);
  //       // });
  //       console.log("done");
  //     }
  //     console.log("out");
  //   });
  // };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1200px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Button type="primary">
            <Link to="../SendCoating">Send for Coating</Link>
          </Button>
          <Button type="primary">
            <Link to="../ListST">Stock Transfer</Link>
          </Button>
          <Button type="primary">
            <CSVLink
              data={
                term == "" && onlyLowStock == false
                  ? allItems
                  : term != "" && onlyLowStock == false
                  ? allItems.filter(
                      (item) =>
                        item.itemName
                          .toString()
                          .toLowerCase()
                          .indexOf(term.toLowerCase()) > -1 ||
                        (item.itemTags &&
                          item.itemTags.some(
                            (item) =>
                              item
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1
                          ))
                    )
                  : term == "" && onlyLowStock == true
                  ? allItems.filter(
                      (item) =>
                        !isNaN(item.remainingQuantity) &&
                        parseFloat(item.remainingQuantity) <
                          parseFloat(item.lowStockValue)
                    )
                  : allItems
                      .filter(
                        (item) =>
                          item.itemName
                            .toString()
                            .toLowerCase()
                            .indexOf(term.toLowerCase()) > -1 ||
                          (item.itemTags &&
                            item.itemTags.some(
                              (item) =>
                                item
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(term.toLowerCase()) > -1
                            ))
                      )
                      .filter(
                        (item) =>
                          !isNaN(item.remainingQuantity) &&
                          parseFloat(item.remainingQuantity) <
                            parseFloat(item.lowStockValue)
                      )
              }
              headers={headersInventory.filter((header) => header !== null)}
              filename={
                "Inventory-" +
                inventoryDate[0].format("DD-MMM-YYYY") +
                "-" +
                inventoryDate[1].format("DD-MMM-YYYY") +
                ".csv"
              }
            >
              Export to CSV
            </CSVLink>
          </Button>
          <Button type="primary" onClick={() => setShowCal(true)}>
            Day Inventory
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "70%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <label style={{ fontWeight: "bold" }}>
            {onlyLowStock ? "Show all material" : "Show only low stock"}
          </label>
          <Switch
            style={{ marginRight: 10 }}
            checked={onlyLowStock}
            onChange={toggleButton}
          />
        </div>
        {term == "" &&
          props.common.role != "Store" &&
          props.common.role != "ProdManager" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              <label style={{ fontWeight: "bold" }}>
                Total Item(s) {allItems.length}
              </label>
              <label style={{ fontWeight: "bold" }}>
                Total Stock {parseFloat(itemCount).toFixed(2)}
              </label>
              <label style={{ fontWeight: "bold" }}>
                Total Value {parseFloat(itemTotal).toFixed(2)}
              </label>
            </div>
          )}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == "" && onlyLowStock == false
                      ? allItems
                      : term != "" && onlyLowStock == false
                      ? allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            (item.itemTags &&
                              item.itemTags.some(
                                (item) =>
                                  item
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(term.toLowerCase()) > -1
                              ))
                        )
                      : term == "" && onlyLowStock == true
                      ? allItems.filter(
                          (item) =>
                            !isNaN(item.remainingQuantity) &&
                            parseFloat(item.remainingQuantity) <
                              parseFloat(item.lowStockValue)
                        )
                      : allItems
                          .filter(
                            (item) =>
                              item.itemName
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              (item.itemTags &&
                                item.itemTags.some(
                                  (item) =>
                                    item
                                      .toString()
                                      .toLowerCase()
                                      .indexOf(term.toLowerCase()) > -1
                                ))
                          )
                          .filter(
                            (item) =>
                              !isNaN(item.remainingQuantity) &&
                              parseFloat(item.remainingQuantity) <
                                parseFloat(item.lowStockValue)
                          )
                  }
                  scroll={{ y: 620 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        visible={modal}
        width="60%"
        style={customStylesAddProducts}
        onCancel={() => setModal(false)}
        footer={null}
        title={<h2 style={{ fontSize: "20px" }}>Color Wise Inventory</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <div className="bill">
          {/* <h1 className="bill-heading">Select Components</h1> */}
          <div className="bill-container">
            <Row gutter={[16, 16]}>
              {(selectedItem && (
                <Col xs={24} md={16} lg={18} offset={2}>
                  <table
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        <td style={{ width: 150 }}>Color</td>
                        <td style={{ width: 150 }}>Incoming Qty</td>
                        <td style={{ width: 150 }}>Sold Qty</td>
                        <td style={{ width: 150 }}>Remaining Qty</td>
                      </tr>
                      {Object.entries(selectedItem).map(([key, value]) => {
                        return (
                          <>
                            <tr
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: 14,
                              }}
                            >
                              <td style={{ width: 150 }}>{key}</td>
                              <td style={{ width: 150 }}>
                                {value.incomingQuantity}
                              </td>
                              <td style={{ width: 150 }}>
                                {value.soldQuantity}
                              </td>
                              <td style={{ width: 150 }}>
                                {value.remainingQuantity}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              )) || (
                <Col xs={24} md={16} lg={18} offset={2}>
                  <p>Color Details not added</p>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showCal}
        width="60%"
        onCancel={() => setShowCal(false)}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            <span>Select a date range for inventory report</span>
            <div className="input-container">
              <RangePicker
                className="input-box"
                placeholder="Select a date for inventory report"
                onChange={(date) => setInventoryDate(date)}
                value={inventoryDate}
              />
            </div>
          </div>
          <div>
            <Table
              columns={ColumnsInventory}
              dataSource={Object.values(inventoryMap)}
              scroll={{ y: 520 }}
              pagination={{ pageSize: 100 }}
            />
          </div>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="discount">
              <Button type="primary" onClick={() => setShowCal(false)}>
                Ok
              </Button>
              <Button type="primary">
                <CSVLink
                  data={Object.values(inventoryMap)}
                  headers={headers}
                  filename={
                    "Inventory Details-" +
                    inventoryDate[0].format("DD-MMM-YYYY") +
                    "-" +
                    inventoryDate[1].format("DD-MMM-YYYY") +
                    ".csv"
                  }
                >
                  Export to CSV
                </CSVLink>
              </Button>
            </div>
          </Col>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
