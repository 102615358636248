import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import moment from "moment";

export async function getAllItemsPagination(type, outletShopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByType,
        variables: {
          type: type,
          outletShopId: outletShopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByType;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getAllItemsPagination100(type, outletShopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByType,
        variables: {
          type: type,
          outletShopId: outletShopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByType;
      allItems = allItems.concat(res.billData);
      if (allItems.length >= 100) break;
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getAllItemsPagination300(type, outletShopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByType,
        variables: {
          type: type,
          outletShopId: outletShopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByType;
      allItems = allItems.concat(res.billData);
      if (allItems.length >= 300) break;
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getAllItemsPagination1(type, outletShopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByType,
        variables: {
          type: type,
          outletShopId: outletShopId,
          nextToken: nextToken,
          limit: 10,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByType;
      allItems = allItems.concat(res.billData);
      if (allItems.length >= 1) break;
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByDatePagination(
  type,
  outletShopId,
  date1,
  date2
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemByTypeAndDate,
        variables: {
          type: type,
          outletShopId: outletShopId,
          date1: date1,
          date2: date2,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemByTypeAndDate;
      // if (res.billData.length == 0) break;
      // console.log(res.nextToken);
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByOutletPagination(type, status, outletShopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemsByOutlet,
        variables: {
          type: type,
          status: status,
          outletShopId: outletShopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemsByOutlet;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByTypeFunc(type, shopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemsByType,
        variables: {
          type: type,
          status: "live",
          shopId: shopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemsByType;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByShopTypeFunc(type, shopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByShopType,
        variables: {
          type: type,
          shopId: shopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByShopType;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByShopTypeFunc100(type, shopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByShopType,
        variables: {
          type: type,
          shopId: shopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByShopType;
      allItems = allItems.concat(res.billData);
      if (allItems.length >= 100) break;
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsByShopTypeFunc300(type, shopId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getAllItemsByShopType,
        variables: {
          type: type,
          shopId: shopId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getAllItemsByShopType;
      allItems = allItems.concat(res.billData);
      if (allItems.length >= 300) break;
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemIdByTypeFunc(type, itemId) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemIdByType,
        variables: {
          type: type,
          itemId: itemId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemIdByType;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);
  allItems = allItems.sort(
    (a, b) =>
      moment(b.addedDate + " " + b.time, "YYYY-MM-DD  HH:mm:ss") -
      moment(a.addedDate + " " + a.time, "YYYY-MM-DD  HH:mm:ss")
  );
  return allItems;
}

export async function getAttndByYearFunc(type, empId, year) {
  let allItems = [];
  try {
    const allTodos = await API.graphql({
      query: queries.getAttndByYear,
      variables: {
        type: type,
        empId: empId,
        year: year,
      },
      fetchPolicy: "no-cache",
    });
    allItems = allTodos.data.getAttndByYear;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
  return allItems;
}

export async function getPayrollFunc(type, month, year) {
  let allItems = [];
  try {
    const allTodos = await API.graphql({
      query: queries.getPayroll,
      variables: {
        type: type,
        month: month,
        year: year,
      },
      fetchPolicy: "no-cache",
    });
    allItems = allTodos.data.getPayroll;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
  return allItems;
}

export async function getItemByVendorPagination(
  type,
  status,
  outletShopId,
  vendorId
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemByVendor,
        variables: {
          type: type,
          status: status,
          outletShopId: outletShopId,
          vendorId: vendorId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemByVendor;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemByOutletIdPagination(
  type,
  status,
  outletShopId,
  outletId
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemByOutletId,
        variables: {
          type: type,
          status: status,
          outletShopId: outletShopId,
          outletId: outletId,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemByOutletId;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemsdatePagination(type, outletShopId, date1, date2) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemByTypedate,
        variables: {
          type: type,
          outletShopId: outletShopId,
          date1: date1,
          date2: date2,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemByTypedate;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getTypeAndDateShopIdPagination(
  type,
  shopId,
  date1,
  date2
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getTypeAndDateShopId,
        variables: {
          type: type,
          shopId: shopId,
          date1: date1,
          date2: date2,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getTypeAndDateShopId;
      // console.log(res.billData.length);
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getProdGroupReportPagination(
  type,
  outletShopId,
  date1,
  date2,
  status1,
  status2
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getProdGroupReport,
        variables: {
          type: type,
          outletShopId: outletShopId,
          date1: date1,
          date2: date2,
          status1: status1,
          status2: status2,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getProdGroupReport;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getItemByTypeDateStatusPagination(
  type,
  outletShopId,
  VOrOId,
  date,
  paymentStatus
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemByTypeDateStatus,
        variables: {
          type: type,
          outletShopId: outletShopId,
          VOrOId: VOrOId,
          date: date,
          paymentStatus: paymentStatus,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getItemByTypeDateStatus;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}

export async function getInvoiceDateOVPagination(
  type,
  outletShopId,
  VOrOId,
  date1,
  date2
) {
  let allItems = [];
  let nextToken = null;
  do {
    try {
      const allTodos = await API.graphql({
        query: queries.getInvoiceDateOV,
        variables: {
          type: type,
          outletShopId: outletShopId,
          VOrOId: VOrOId,
          date1: date1,
          date2: date2,
          nextToken: nextToken,
          limit: 100,
        },
        fetchPolicy: "no-cache",
      });
      let res = allTodos.data.getInvoiceDateOV;
      allItems = allItems.concat(res.billData);
      nextToken = res.nextToken;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } while (nextToken);

  return allItems;
}
